@import '../../../../scss/theme-bootstrap';

.basic-formatter {
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  text-align: center;
  &--large {
    max-width: $max-width-large;
  }
  &--medium {
    max-width: $max-width;
  }
  &--small {
    max-width: $max-width-small-formatters;
  }
  &--full {
    max-width: 100%;
  }
  &__items {
    padding-top: 15px;
    @include breakpoint($medium-up) {
      padding-top: 20px;
    }
  }
}
